import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

const formatPct = d3.format('.0%');
const formatDec = d3.format(',d');

function getPop(area, period, pop) {
  if (period === 'C' && area.current_period_dates && area.estimated_population_current) {
    const _pop = pop ? pop : 0;
    return formatDec(_pop);
  }
  if (period === 'P' && area.projected_period_dates && area.estimated_population_projected) {
    const _pop = pop ? pop : 0;
    return formatDec(_pop);
  }
  if (
    period === 'A' &&
    area.second_projected_period_dates &&
    area.estimated_population_second_projected
  ) {
    const _pop = pop ? pop : 0;
    return formatDec(_pop);
  }
  return '';
}

function getPct(area, period, pct) {
  if (period === 'C' && area.current_period_dates && area.estimated_population_current) {
    const _pct = pct ? pct : 0;
    return formatPct(_pct);
  }
  if (period === 'P' && area.projected_period_dates && area.estimated_population_projected) {
    const _pct = pct ? pct : 0;
    return formatPct(_pct);
  }
  if (
    period === 'A' &&
    area.second_projected_period_dates &&
    area.estimated_population_second_projected
  ) {
    const _pct = pct ? pct : 0;
    return formatPct(_pct);
  }
  return '';
}

class PopulationTrackingSheetRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
    this.state = {
      analysisClosed: {},
      areaClosed: {},
    };
  }

  onRowClick(area) {
    const { isSelected, updateSelectedArea } = this.props;
    if (isSelected) {
      updateSelectedArea(null);
    } else {
      updateSelectedArea(area.aar_id);
    }
  }

  onRowMouseOver(area) {
    const { updateHighlightedAreas } = this.props;
    updateHighlightedAreas([area.aar_id]);
  }

  onRowMouseOut() {
    const { updateHighlightedAreas } = this.props;
    updateHighlightedAreas([]);
  }

  getClass() {
    const { isSelected, isFaded, isHighlighted, level } = this.props;

    let rowClass = 'pop-row';

    if (isSelected) {
      rowClass += ' pop-row--selected';
    }

    if (isFaded) {
      rowClass += ' pop-row--faded';
    }

    if (isHighlighted) {
      rowClass += ' pop-row--highlighted';
    }

    rowClass += ` ${level}`;
    return rowClass;
  }

  getGroupName() {
    const { area, level } = this.props;
    const { analysisClosed, areaClosed } = this.state;
    const analysisIsClosed =
      analysisClosed.hasOwnProperty(area.anl_id) && analysisClosed[area.anl_id];
    const areaIsClosed = areaClosed.hasOwnProperty(area.aag_id) && areaClosed[area.aag_id];

    const analysisIcon = !analysisIsClosed ? (
      <FontAwesomeIcon icon={faChevronDown} />
    ) : (
      <FontAwesomeIcon icon={faChevronUp} />
    );
    const areaIcon = !areaIsClosed ? (
      <FontAwesomeIcon icon={faChevronDown} />
    ) : (
      <FontAwesomeIcon icon={faChevronUp} />
    );

    // const groupText = level === 'level-0' ? `${countryIsClosed ? 'Show' : 'Hide'} Areas` : '';
    const groupText = '';
    const padding = level === 'level-2' ? <React.Fragment>&nbsp;&nbsp;</React.Fragment> : '';
    const _area =
      area && area.area.length > 110
        ? area.area.replace(/^(.{110}[^\s\d]*).*/gi, '$1 ...')
        : area.area;
    return (
      <React.Fragment>
        <div className={`pts-pop-table__group-name ${level} text-left`}>
          {padding}
          {_area}
          <span className="pts-pop-table__group">
            {' '}
            <span className="pts-pop-table__group-text">{groupText}</span>
            {level === 'level-0' ? analysisIcon : areaIcon}
          </span>
        </div>
      </React.Fragment>
    );
  }

  getAreaName() {
    const { area, level } = this.props;
    const _area =
      level !== 'level-1' && area.area && area.area.length > 100
        ? area.area.replace(/^(.{100}[^\s\d]*).*/gi, '$1 ...')
        : area.area;
    return <section>{_area}</section>;
  }

  getSubAreaName() {
    const { area } = this.props;
    const _area =
      area.area.length > 50 ? area.area.replace(/^(.{50}[^\s\d]*).*/gi, '$1 ...') : area.area;
    return (
      <section className="pop-tabl__sub-area">
        &nbsp;&nbsp; <span className="pop-table__area-text">{_area}</span>
      </section>
    );
  }

  getName() {
    const { level } = this.props;
    if (level === 'level-0' || level === 'level-2') {
      return this.getGroupName();
    }
    if (level === 'level-3') {
      return this.getSubAreaName();
    }
    return this.getAreaName();
  }

  render() {
    const { area, level, onGroupClick } = this.props;
    const rowClass = this.getClass();
    const nameBlock = this.getName();
    let click,
      analysisConditionPrefix = area.condition === 'C' ? 'chronic-' : '';
    if (level === 'level-2' || level === 'level-0') {
      click = () => {
        const { analysisClosed, areaClosed } = this.state;
        if (level === 'level-0') {
          let _analysisClosed = analysisClosed;
          if (analysisClosed.hasOwnProperty(area.anl_id)) {
            _analysisClosed[area.anl_id] = !_analysisClosed[area.anl_id];
          } else {
            _analysisClosed[area.anl_id] = true;
          }
          this.setState({ analysisClosed: _analysisClosed });
        } else {
          let _areaClosed = areaClosed;
          if (areaClosed.hasOwnProperty(area.aag_id)) {
            _areaClosed[area.aag_id] = !_areaClosed[area.aag_id];
          } else {
            _areaClosed[area.aag_id] = true;
          }
          this.setState({ areaClosed: _areaClosed });
        }
        onGroupClick(area, level === 'level-0' ? 'anl_id' : 'aag_id');
      };
    } else {
      click = () => {}; //this.onRowClick(area);
    }
    // % of country pop
    const currentPercentCountryPop =
      area.census_population && area.current_period_dates && area.estimated_population_current
        ? area.estimated_population_current / area.census_population
        : null;
    const projectedPercentCountryPop =
      area.census_population && area.projected_period_dates && area.estimated_population_projected
        ? area.estimated_population_projected / area.census_population
        : null;
    const secondProjectedPercentCountryPop =
      area.census_population &&
      area.second_projected_period_dates &&
      area.estimated_population_second_projected
        ? area.estimated_population_second_projected / area.census_population
        : null;
    return (
      <tr className={rowClass} onClick={click}>
        <td
          className="pts-pop-table__name-col analysis-name sticky-column first-column"
          data-aag-id={area.aag_id}
          data-anl-id={area.anl_id}
          title={area.area}
        >
          <div>{nameBlock}</div>
        </td>
        <td className="pts-pop-table__name-col analysis-date sticky-column second-column">
          <div>{level === 'level-0' ? area.analysis_date : ''}</div>
        </td>
        <td className="phase-col-val pop-figures sticky-column third-column">
          <div>
            {level === 'level-0' || level === 'level-5' ? formatDec(area.census_population) : ''}
          </div>
        </td>
        <td className="phase-col-val pop-figures black-thick-border-left pop-figures">
          <div>{getPop(area, 'C', area.estimated_population_current)}</div>
        </td>
        <td className="phase-col-val percentage">
          <div>
            {level === 'level-0'
              ? getPct(area, 'C', isFinite(currentPercentCountryPop) ? currentPercentCountryPop : 0)
              : ''}
          </div>
        </td>
        <td
          className={`phase-col-val area-phase ${analysisConditionPrefix}phase-${
            level === 'level-3' && area.estimated_population_current && area.current_period_dates
              ? area.overall_phase_C
              : ''
          }-col`}
        >
          <div>
            {level === 'level-3' && area.estimated_population_current && area.current_period_dates
              ? area.overall_phase_C
              : ''}
          </div>
        </td>
        <td className="phase-col-val analysis-period">
          <div>{level === 'level-0' ? area.current_period_dates : ''}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-1-col phase-col-val pop-figures`}>
          <div>{getPop(area, 'C', area.phase1_C_population)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-1-col phase-col-pct percentage`}>
          <div>{getPct(area, 'C', area.phase1_C_percentage)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-2-col phase-col-val pop-figures`}>
          <div>{getPop(area, 'C', area.phase2_C_population)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-2-col phase-col-pct percentage`}>
          <div>{getPct(area, 'C', area.phase2_C_percentage)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-3-col phase-col-val pop-figures`}>
          <div>{getPop(area, 'C', area.phase3_C_population)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-3-col phase-col-pct percentage`}>
          <div>{getPct(area, 'C', area.phase3_C_percentage)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-4-col phase-col-val pop-figures`}>
          <div>{getPop(area, 'C', area.phase4_C_population)}</div>
        </td>
        <td className={`${analysisConditionPrefix}phase-4-col phase-col-pct percentage`}>
          <div>{getPct(area, 'C', area.phase4_C_percentage)}</div>
        </td>
        {area.condition !== 'C' && (
          <React.Fragment>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'C', area.phase5_C_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-pct percentage`}>
              <div>{getPct(area, 'C', area.phase5_C_percentage)}</div>
            </td>
          </React.Fragment>
        )}

        <td className="phase-index-plus phase-col-val pop-figures">
          <div>{getPop(area, 'C', area.phase6_C_population)}</div>
        </td>
        <td className="phase-index-plus phase-col-pct percentage grey-thick-border-right">
          <div>{getPct(area, 'C', area.phase6_C_percentage)}</div>
        </td>

        {area.condition !== 'C' && (
          <React.Fragment>
            {/** projected **/}
            <td className="phase-col-val pop-figures">
              <div>{getPop(area, 'P', area.estimated_population_projected)}</div>
            </td>
            <td className="phase-col-val pop-figures">
              <div>
                {level === 'level-0' || level === 'level-5'
                  ? getPct(
                      area,
                      'P',
                      isFinite(projectedPercentCountryPop) ? projectedPercentCountryPop : 0
                    )
                  : ''}
              </div>
            </td>
            <td
              className={`phase-col-val area-phase ${analysisConditionPrefix}phase-${
                level === 'level-3' &&
                area.estimated_population_projected &&
                area.projected_period_dates
                  ? area.overall_phase_P
                  : ''
              }-col`}
            >
              <div>
                {level === 'level-3' &&
                area.estimated_population_projected &&
                area.projected_period_dates
                  ? area.overall_phase_P
                  : ''}
              </div>
            </td>
            <td className="phase-col-val analysis-period">
              <div>{level === 'level-0' ? area.projected_period_dates : ''}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-1-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'P', area.phase1_P_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-1-col phase-col-pct percentage`}>
              <div>{getPct(area, 'P', area.phase1_P_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-2-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'P', area.phase2_P_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-2-col phase-col-pct percentage`}>
              <div>{getPct(area, 'P', area.phase2_P_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-3-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'P', area.phase3_P_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-3-col phase-col-pct percentage`}>
              <div>{getPct(area, 'P', area.phase3_P_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-4-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'P', area.phase4_P_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-4-col phase-col-pct percentage`}>
              <div>{getPct(area, 'P', area.phase4_P_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'P', area.phase5_P_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-pct percentage`}>
              <div>{getPct(area, 'P', area.phase5_P_percentage)}</div>
            </td>
            <td className="phase-index-plus phase-col-val pop-figures">
              <div>{getPop(area, 'P', area.phase6_P_population)}</div>
            </td>
            <td className="phase-index-plus phase-col-pct percentage grey-thick-border-right">
              <div>{getPct(area, 'P', area.phase6_P_percentage)}</div>
            </td>

            {/** second projected **/}
            <td className="phase-col-val pop-figures">
              <div>
                {area.second_projected_period_dates
                  ? getPop(area, 'A', area.estimated_population_second_projected)
                  : ''}
              </div>
            </td>
            <td className="phase-col-val pop-figures">
              <div>
                {level === 'level-0' || level === 'level-5'
                  ? getPct(
                      area,
                      'A',
                      isFinite(secondProjectedPercentCountryPop)
                        ? secondProjectedPercentCountryPop
                        : 0
                    )
                  : ''}
              </div>
            </td>
            <td
              className={`phase-col-val area-phase ${analysisConditionPrefix}phase-${
                level === 'level-3' &&
                area.estimated_population_second_projected &&
                area.second_projected_period_dates
                  ? area.overall_phase_A
                  : ''
              }-col`}
            >
              <div>
                {level === 'level-3' &&
                area.estimated_population_second_projected &&
                area.second_projected_period_dates
                  ? area.overall_phase_A
                  : ''}
              </div>
            </td>
            <td className="phase-col-val analysis-period">
              <div>{level === 'level-0' ? area.second_projected_period_dates : ''}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-1-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'A', area.phase1_A_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-1-col phase-col-pct percentage`}>
              <div>{getPct(area, 'A', area.phase1_A_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-2-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'A', area.phase2_A_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-2-col phase-col-pct percentage`}>
              <div>{getPct(area, 'A', area.phase2_A_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-3-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'A', area.phase3_A_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-3-col phase-col-pct percentage`}>
              <div>{getPct(area, 'A', area.phase3_A_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-4-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'A', area.phase4_A_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-4-col phase-col-pct percentage`}>
              <div>{getPct(area, 'A', area.phase4_A_percentage)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-val pop-figures`}>
              <div>{getPop(area, 'A', area.phase5_A_population)}</div>
            </td>
            <td className={`${analysisConditionPrefix}phase-5-col phase-col-pct percentage`}>
              <div>{getPct(area, 'A', area.phase5_A_percentage)}</div>
            </td>
            <td className="phase-index-plus phase-col-val pop-figures">
              <div>{getPop(area, 'A', area.phase6_A_population)}</div>
            </td>
            <td className="phase-index-plus phase-col-pct percentage grey-thick-border-right">
              <div>{getPct(area, 'A', area.phase6_A_percentage)}</div>
            </td>
          </React.Fragment>
        )}
      </tr>
    );
  }
}

PopulationTrackingSheetRow.propTypes = {
  area: PropTypes.shape({
    country: PropTypes.string,
    group_name: PropTypes.string,
    area_population: PropTypes.number,
    overall_phase: PropTypes.number,
    overall_population: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase1_population: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase1_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase2_population: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase2_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase3_population: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase3_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase4_population: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase4_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase5_population: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase5_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  allClosed: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
  analysisPeriod: PropTypes.string,
  analysisCondition: PropTypes.string,
  columnSpanDefinition: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default PopulationTrackingSheetRow;
