/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* global saveAs */

import mapboxgl from 'mapbox-gl';
import ipcLogo from '../Header/IPC_Logo_sm.png';
import cHLogo from '../Header/CH-logo-sm.png';

const acuteLegend = [
  {
    color: '#cef9ce',
    title: '1 - Minimal',
  },
  {
    color: '#f9e43c',
    title: '2 - Stressed',
  },
  {
    color: '#e4781f',
    title: '3 - Crisis',
  },
  {
    color: '#c60813',
    title: '4 - Emergency',
  },
  {
    color: '#640000',
    title: '5 - Famine',
  },
  {
    color: 'famine-likely-pattern',
    title: 'Famine Likely',
  },
  {
    color: '#a6a6a6',
    title: 'Areas with inadequete evidence',
  },
  {
    color: '#ffffff',
    title: 'Areas not analyzed',
  },
];

const cHAcuteLegend = [
  {
    color: '#cef9ce',
    title: '1 - Minimal',
  },
  {
    color: '#f9e43c',
    title: '2 - Stressed',
  },
  {
    color: '#e4781f',
    title: '3 - Crisis',
  },
  {
    color: '#c60813',
    title: '4 - Emergency',
  },
  {
    color: '#640000',
    title: '5 - Famine',
  },
  {
    color: '#a6a6a6',
    title: 'Not analyzed',
  },
];

const chronicLegend = [
  {
    color: 'rgb(204, 255, 204)',
    title: '1 - Minimal CFI',
  },
  {
    color: 'rgb(203, 201, 226)',
    title: '2 - Mild CFI',
  },
  {
    color: 'rgb(158, 154, 200)',
    title: '3 - Moderate CFI',
  },
  {
    color: 'rgb(106, 81, 163)',
    title: '4 - Severe CFI',
  },
  {
    color: 'rgb(176,176,176)',
    title: 'Areas with inadequete evidence',
  },
  {
    color: '#ffffff',
    title: 'Areas not analyzed',
  },
];

// const disclaimerMultiLine = [
//   `Disclaimer: The information shown on this map does not imply that the IPC and CH officially recognizes or endorses physical and political boundaries. All national population figures are based on official country population estimates. IPC estimates are those published in `,
//   `country IPC reports. It is acknowledged that, in some cases, due to rounding and process related issues, figures at subnational level or for specific IPC Levels may not add up to totals.`,
// ];
const disclaimerMultiLine = [
  `Disclaimer: The information shown on this map does not imply that the IPC and CH officially recognizes or endorses physical and political boundaries.`,
];
const cHDisclaimerMultiLine = [
  `Disclaimer: The information shown on this map does not imply that the CH officially recognizes or endorses physical and political boundaries.`,
];

const toBlob = function toBlob(uri) {
  const mime = uri.split(',')[0].split(':')[1].split(';')[0];
  const bytes = atob(uri.split(',')[1]);
  const len = bytes.length;
  const buffer = new window.ArrayBuffer(len);
  const arr = new window.Uint8Array(buffer);

  for (let i = 0; i < len; i += 1) {
    arr[i] = bytes.charCodeAt(i);
  }

  return new Blob([arr], { type: mime });
};

const CanvasPrototype = window.HTMLCanvasElement.prototype;

if (!CanvasPrototype.toBlob && CanvasPrototype.toDataURL) {
  CanvasPrototype.toBlob = function canvasToBlob(callback, type, quality) {
    callback(toBlob(this.toDataURL(type, quality)));
  };
}

// eslint-disable-next-line no-unused-vars
function createPrintMap(mapboxMap, div, inches) {
  const mapBBox = mapboxMap._container.getBoundingClientRect();
  const printWidth = inches * 96;
  const { width, height } = mapBBox;
  const scale = width / printWidth;
  const dpi = 300 / scale;

  const style = mapboxMap.getStyle();
  const zoom = mapboxMap.getZoom();
  const center = mapboxMap.getCenter();
  const bearing = mapboxMap.getBearing();
  const pitch = mapboxMap.getPitch();
  // Calculate pixel ratio
  Object.defineProperty(window, 'devicePixelRatio', {
    get: () => dpi / 96,
  });

  // Create map container
  // eslint-disable-next-line no-param-reassign
  div.className = 'hidden-map';
  document.body.appendChild(div);
  const container = document.createElement('div');
  container.style.width = `${width}px`;
  container.style.height = `${height}px`;
  div.appendChild(container);

  // $('.export .btn').addClass('disabled');

  // Render map
  return new mapboxgl.Map({
    container,
    center,
    zoom,
    style,
    bearing,
    pitch,
    interactive: false,
    preserveDrawingBuffer: true,
    fadeDuration: 0,
    attributionControl: false,
  });
}

function getTotalBBox(mainMaps) {
  if (mainMaps.length === 1) return mainMaps[0].map._container.getBoundingClientRect();
  let bbox1 = { x: 0, y: 0, width: 0, height: 0 };
  let bbox2 = { x: 0, y: 0, width: 0, height: 0 };
  let bbox3 = { x: 0, y: 0, width: 0, height: 0 };
  if (typeof mainMaps[0] !== 'undefined') {
    bbox1 = mainMaps[0].map._container.getBoundingClientRect();
  }
  if (typeof mainMaps[1] !== 'undefined') {
    bbox2 = mainMaps[1].map._container.getBoundingClientRect();
  }
  if (typeof mainMaps[2] !== 'undefined') {
    bbox3 = mainMaps[2].map._container.getBoundingClientRect();
  }
  return {
    x: Math.min(bbox1.x, bbox2.x, bbox3.x),
    y: Math.min(bbox1.y, bbox2.y, bbox3.y),
    width: bbox1.width + bbox2.width + bbox3.width + mainMaps.length * 20,
    height: Math.max(bbox1.height, bbox2.height, bbox3.height),
  };
}

// essentially, promisify the render method
function renderMap(mapItem, widthInches) {
  return new Promise(resolve => {
    // create the hidden container and map... counting on these being cleaned up elsewhere
    const hidden = document.createElement('div');
    // eslint-disable-next-line no-shadow
    const renderMap = createPrintMap(mapItem.map, hidden, widthInches);
    renderMap.once('idle', () => {
      // send back the original data, extended with the rendered canvas etc
      resolve({ ...mapItem, canvas: renderMap.getCanvas(), renderMap, hidden });
    });
  });
}
/**
 * added activeAnalysisPeriod - to respect the toggling on the analysis details view
 * added activeAnalysisCondition - to respect the condition on AP global view when nothing is selected.
 * */
export default function exportMap(
  mapItems,
  widthInches = 12,
  selectedCountry = {},
  dateText = '',
  activeAnalysisPeriod = null,
  activeAnalysisCondition = null,
  isCHMap = false
) {
  if (!mapItems || !Object.keys(mapItems).length) return;

  // console.log('dateText', dateText);

  // for cleanup afterward
  const actualPixelRatio = window.devicePixelRatio;

  // get bbox for every map/inset
  const mapsToRender = Object.values(mapItems)
    .map(d => ({
      ...d,
      bbox: d.map._container.getBoundingClientRect(),
    }))
    .filter(d => d.bbox.width > 0);

  // assumes either one main main, or a split left and right one
  // const mainMaps = mapsToRender.filter(d => d.type === 'main');
  const mainMaps = mapsToRender.filter(d => d.type.includes('main-'));
  if (!mainMaps.length) return; // there's gotta be at least one main map

  // get total pixel bbox (main map or left + right maps), in order to calculate inches for each map
  const totalBBox = getTotalBBox(mainMaps);

  // get render promises (see function above)
  const renders = mapsToRender.map(d =>
    renderMap(d, widthInches * (d.bbox.width / totalBBox.width))
  );

  Promise.all(renders).then(renderedData => {
    // const mainMapsRendered = renderedData.filter(d => d.type === 'main');
    const mainMapsRendered = renderedData.filter(d => d.type.includes('main-'));

    // get combined width/height (in case of split view)
    const mapHeight = Math.max(...mainMapsRendered.map(d => d.canvas.height));
    const headerHeight = 160;
    const footerHeight = 140;
    const legendHeight = 140;
    const canvasWidth = mainMapsRendered.reduce((total, d) => total + d.canvas.width, 0);
    let canvasHeight = mapHeight + headerHeight + footerHeight + legendHeight;

    // canvas to download
    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext('2d');

    // draw header
    ctx.beginPath();
    ctx.fillStyle = isCHMap ? '#1C9547' : '#125F9A';
    ctx.fillRect(0, 0, canvasWidth, headerHeight);

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, canvasWidth - 360, 20);

      ctx.fillStyle = '#ffffff';
      ctx.font = 'bold 36px arial, lucida grandriale, lucida sans unicode, tahoma, sans-serif';
      const headerText = isCHMap
        ? `Cadre Harmonisé Acute Food and Nutrition Insecurity Classification`
        : `${!dateText ? 'IPC ' : ''} ${
            activeAnalysisCondition && activeAnalysisCondition === 'C' ? 'Chronic' : 'Acute'
          } Food Insecurity Classifications`;
      ctx.fillText((selectedCountry && selectedCountry.full_country_name) || headerText, 20, 60);

      const leftMap = mainMapsRendered.find(d => d.position === 'left' || d.position === undefined);
      const rightMap = mainMapsRendered.find(d => d.position === 'right');
      const secondRightMap = mainMapsRendered.find(d => d.position === '2nd-right');

      let _dateText = `Date: ${dateText}`;
      if (selectedCountry && (selectedCountry.analysis_name || selectedCountry.title)) {
        ctx.font = 'bold 26px arial, lucida grandriale, lucida sans unicode, tahoma, sans-serif';
        ctx.fillText(selectedCountry.analysis_name || selectedCountry.title, 20, 100);

        if (mainMaps.length > 1) {
          if (leftMap) {
            _dateText = `Current: From ${selectedCountry.current_date_range}`;
          }
          if (rightMap) {
            _dateText += `     Projected: From ${selectedCountry.projected_date_range}`;
          }
          if (secondRightMap) {
            _dateText += `     Second Projected: From ${selectedCountry.second_projected_date_range}`;
          }
        } else {
          const from_thru_dates = `${selectedCountry.current_from_date} - ${selectedCountry.current_thru_date}`;
          const dateRange =
            activeAnalysisPeriod === 'A'
              ? 'second_projected_date_range'
              : activeAnalysisPeriod === 'P'
              ? 'projected_date_range'
              : 'current_date_range';
          _dateText =
            selectedCountry.condition === 'C'
              ? `Created: ${
                  selectedCountry.analysis_date
                    ? selectedCountry.analysis_date
                    : selectedCountry.current_from_date
                }`
              : `${
                  activeAnalysisPeriod === 'A'
                    ? 'Second Projected'
                    : activeAnalysisPeriod === 'P'
                    ? 'Projected'
                    : 'Current'
                }: ${selectedCountry[dateRange] ? selectedCountry[dateRange] : from_thru_dates}`;
        }
      }

      ctx.font = 'bold 24px arial, lucida grandriale, lucida sans unicode, tahoma, sans-serif';
      ctx.fillText(_dateText, 20, 140);

      // draw main map or left side of split view
      let xstart = 1;
      let totalMaps = 0;
      if (leftMap) {
        ctx.drawImage(leftMap.canvas, xstart, headerHeight);
        totalMaps++;
      }

      // draw Projected split map, if any
      if (rightMap) {
        if (leftMap) xstart = xstart + leftMap.canvas.width + 10;
        ctx.drawImage(rightMap.canvas, xstart, headerHeight);
        totalMaps++;
      }

      // draw 2nd Projected split map, if any
      if (secondRightMap) {
        if (rightMap) xstart = xstart + rightMap.canvas.width + 10;
        ctx.drawImage(secondRightMap.canvas, xstart, headerHeight);
        totalMaps++;
      }

      // draw each inset
      const insetMapsRendered = renderedData.filter(d => d.type === 'inset');
      insetMapsRendered.forEach(inset => {
        const { bbox } = inset;
        // get scaled position from original pixel bboxes
        const x = (bbox.x - totalBBox.x) * (canvasWidth / totalBBox.width);
        const y =
          (bbox.y - totalBBox.y - bbox.height * (totalMaps === 2)) *
            (canvasWidth / totalBBox.width) +
          headerHeight;

        // white stroke (actually a fill behind the image)
        ctx.beginPath();
        ctx.fillStyle = '#cccccc';
        ctx.fillRect(x - 4, y - 4, inset.canvas.width + 8, inset.canvas.height + 8);
        // map image
        ctx.drawImage(inset.canvas, x, y);
      });

      // legend
      let legendX = 2;
      let legendY = headerHeight + mapHeight + 40;
      // ctx.fillStyle = '#ffffff';
      // ctx.fillRect(0, legendY, canvasWidth, legendHeight);
      ctx.fillStyle = '#333333';
      ctx.font = 'normal 20px arial, lucida grandriale, lucida sans unicode, tahoma, sans-serif';
      const mapKey =
        selectedCountry && selectedCountry.condition
          ? selectedCountry.condition === 'C'
            ? 'Chronic'
            : 'Acute'
          : activeAnalysisCondition && activeAnalysisCondition === 'C'
          ? 'Chronic'
          : 'Acute';

      const mapKeyText = isCHMap
        ? `CH Map Key: Acute Food and Nutrition Insecurity`
        : `IPC Map Key: ${mapKey} Food Insecurity`;
      ctx.fillText(mapKeyText, 0, legendY);
      legendY += 30;
      const legend = mapKey === 'Chronic' ? chronicLegend : isCHMap ? cHAcuteLegend : acuteLegend;
      legend.forEach(item => {
        const { width } = ctx.measureText(item.title);
        if (legendX + 50 + 20 + width + 20 > canvasWidth) {
          legendY += 70;
          canvasHeight += 70;
          canvas.height = canvasHeight;
        }
        if (item.color === 'famine-likely-pattern') {
          const createPinstripeCanvas = () => {
            const patternCanvas = document.createElement('canvas');
            const pctx = patternCanvas.getContext('2d', { antialias: true });
            const colour = '#640000';

            const CANVAS_SIDE_LENGTH = 5;
            const WIDTH = CANVAS_SIDE_LENGTH;
            const HEIGHT = CANVAS_SIDE_LENGTH;
            const DIVISIONS = 4;

            patternCanvas.width = WIDTH;
            patternCanvas.height = HEIGHT;
            pctx.fillStyle = colour;

            // Top line
            pctx.beginPath();
            pctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
            pctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
            pctx.lineTo(0, 0);
            pctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
            pctx.fill();

            // Middle line
            pctx.beginPath();
            pctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
            pctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
            pctx.lineTo(0, HEIGHT);
            pctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
            pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
            pctx.lineTo(WIDTH, 0);
            pctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
            pctx.fill();

            // Bottom line
            pctx.beginPath();
            pctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
            pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
            pctx.lineTo(WIDTH, HEIGHT);
            pctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
            pctx.fill();

            return patternCanvas;
          };
          ctx.fillStyle = ctx.createPattern(createPinstripeCanvas(), 'repeat');
        } else {
          ctx.fillStyle = item.color;
        }
        ctx.fillRect(legendX, legendY, 30, 30);
        ctx.beginPath();
        ctx.strokeStyle = '#333333';
        ctx.rect(legendX, legendY, 30, 30);
        ctx.stroke();
        legendX += 50;
        ctx.fillStyle = '#333333';
        ctx.fillText(item.title, legendX, legendY + 25);
        legendX += width + 20;
      });

      // disclaimer
      legendY += 90;
      ctx.font = 'normal 20px arial, lucida grandriale, lucida sans unicode, tahoma, sans-serif';
      const disclaimerY = legendY;
      // ctx.fillStyle = '#ffffff';
      // ctx.fillRect(0, headerHeight + mapHeight + legendHeight + 50, canvasWidth, footerHeight);
      ctx.fillStyle = '#333333';

      let _disclaimerMultiLine = isCHMap ? cHDisclaimerMultiLine : disclaimerMultiLine;
      if (secondRightMap && rightMap && leftMap) {
        // hack to ensure disclaimer fits whole page when we have 2nd projected
        _disclaimerMultiLine = disclaimerMultiLine.join(' ').split('IPC Phases/Levels');
        // couldnt figure out a way to  forward look the expression above https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Assertions#Other_assertions
        if (_disclaimerMultiLine.length > 1)
          _disclaimerMultiLine[1] = `IPC Phases/Levels${_disclaimerMultiLine[1]}`;
      }
      _disclaimerMultiLine.forEach((line, n) => {
        ctx.fillText(line, 0, disclaimerY + n * 30);
      });

      const sourceText = isCHMap
        ? 'Cadre Harmonisé'
        : !dateText
        ? 'Integrated Food Security Phase Classification'
        : 'IPC and Cadre Harmonisé*';
      const sourceTextDescription =
        isCHMap || !dateText
          ? ''
          : '*Cadre Harmonisé applies to countries in West Africa, the Sahel and Cameroon.';
      ctx.fillText(`Source: ${sourceText}`, 0, disclaimerY + _disclaimerMultiLine.length * 40);
      ctx.fillText(sourceTextDescription, 0, disclaimerY + _disclaimerMultiLine.length * 70);

      // download final image!
      let filename = !dateText
        ? 'Integrated Food Security Phase Classification'
        : `${
            activeAnalysisCondition && activeAnalysisCondition === 'C' ? 'Chronic' : 'Acute'
          } Food Insecurity Classification`;

      if (selectedCountry) {
        filename =
          selectedCountry.analysis_name || selectedCountry.title
            ? `${selectedCountry.title || selectedCountry.full_country_name}_${(
                selectedCountry.analysis_name || selectedCountry.title
              )
                .replace(/\W/g, '_')
                .toLowerCase()}`
            : filename;
      }

      if (isCHMap) filename = 'Cadre Harmonise Acute Food and Nutrition Insecurity Classification';

      canvas.toBlob(blob => {
        saveAs(blob, `${filename}.png`);
      });

      // cleanup
      renderedData.forEach(d => {
        d.renderMap.remove();
        d.hidden.parentNode.removeChild(d.hidden);
      });

      Object.defineProperty(window, 'devicePixelRatio', {
        get() {
          return actualPixelRatio;
        },
      });
    };
    img.src = isCHMap ? cHLogo : ipcLogo;
  });
}
